/* eslint-disable */
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";

import { callFunctionsAPI } from "../../helpers/action";
import {
  isArrayExists,
  validateEmail,
  isObjectExists,
} from "../../helpers/validation";
import {
  getSnapshotDataBySchema,
  getDocDataBySchema,
} from "../../helpers/schemas";
import { cloneCollections } from "../../helpers/data";

/* helpers start */
const sampleFunc = () => {
  return new Promise((resolve, reject) => {});
};

const triggerConfirmationEmailAPI = (formData) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({
      url: "class",
      action: "trigger_confirmation_email",
      formData,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const doAsyncConfirmationAPICall = async ({
  schedule_id,
  trainees,
  cc_parent,
  cc_principle,
  cc_coordinator,
  email_attachments,
}) => {
  const list = [];
  const limits = 2;
  let count = 1,
    batch = [];

  if (trainees && isArrayExists(trainees)) {
    _forEach(trainees, (trainee) => {
      batch.push(trainee);
      if (count === limits) {
        list.push(batch); // add to list
        // then reset
        count = 1;
        batch = [];
      } else {
        count++;
      } // end - count
    });

    // if batch is not empty - add to list
    if (batch && isArrayExists(batch)) list.push(batch);
  } // end - trainees

  if (list && isArrayExists(list)) {
    let cIndex = 0;
    while (cIndex < list.length) {
      await triggerConfirmationEmailAPI({
        schedule_id,
        trainees: cloneCollections(list[cIndex]),
        cc_parent,
        cc_principle,
        cc_coordinator,
        email_attachments,
      })
        .then(() => {
          cIndex++;
        })
        .catch((error) => {
          let errorMessage =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : error.message ||
                "Unable to import registration. Please try again.";
          throw new Error(errorMessage);
        });
    }
  } // end - contacts

  return "done";
};

/* helper end */

export const fb_getClassConfirmation = (schedule_id) => {
  return new Promise((resolve, reject) => {
    let promises = [];
    promises.push(
      callFunctionsAPI({
        url: "class",
        action: "get_class_confirmation",
        formData: { schedule_id },
      })
    );

    Promise.all(promises)
      .then((results) => {
        let item = {};
        if (results && results[0] && isObjectExists(results[0])) {
          item = results[0];
        } // end - results[0]
        resolve(item);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fb_updateClassConfirmationEmail = (formData) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({
      url: "class",
      action: "update_class_confirmation",
      formData,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fb_triggerPreviewEmail = (formData) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({
      url: "class",
      action: "trigger_confirmation_preview_email",
      formData,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fb_triggerConfirmationEmail = (formData) => {
  return new Promise((resolve, reject) => {
    doAsyncConfirmationAPICall(formData)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fb_triggerReminderEmail = (formData) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({
      url: "class",
      action: "trigger_reminder_email",
      formData,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fb_triggerTrialEmail = (formData) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({ url: "class", action: "trigger_trial_email", formData })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
