/* eslint-disable */
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from "firebase/app";
import moment from "moment";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toLower from "lodash/toLower";
import _random from "lodash/random";

import AppWrapper from "../../components/AppWrapper";
import ModalView from "../../components/ModalView";
import FormSelect from "../../components/FormSelect";
import FormDatePicker from "../../components/FormDatePicker";

import List from "./list";

import {
  InfoButton,
  GreyButton,
  ButtonGroup,
  AInfoLink,
  InverseButton,
  ErrorButton,
} from "../../styles/button";
import { SuccessTag, GreyTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";
import { InfoBox } from "../../styles/message";

import { isAdmin, isAdminType } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import { callFunctionsAPI, getAPIErrorMessage } from "../../helpers/action";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { getMomentTime } from "../../helpers/date";
import {
  cloneCollections,
  doPagination,
  doArraySearch,
  getSelectOptions,
  getSelectValues,
  getSelectedValue,
} from "../../helpers/data";

import { getOneOffBadges } from "../../actions/badges";
import { getMaintenance } from "../../actions/maintenance";
import { getRegions } from "../../actions/m_regions";
import { resetRedux } from "../../actions/misc";
import {
  toggleModalDeleting,
  toggleModalProcessing,
  toggleLoader,
} from "../../actions/global";

import { PLATFORM_ADMIN_DEFAULT_REGION } from "../../constants";

// callFunctionsAPI({ url: 'badge', action: 'issue_oneoff_badge', formData: submitData })

const triggerBadgeIssue = async ({
  id,
  name,
  notes = "",
  badge_id,
  recipients,
  status,
}) => {
  const list = [];
  const limits = 40;
  let count = 1,
    batch = [];

  if (recipients && isArrayExists(recipients)) {
    _forEach(recipients, (recipient) => {
      batch.push(recipient);
      if (count === limits) {
        list.push(batch); // add to list
        // then reset
        count = 1;
        batch = [];
      } else {
        count++;
      } // end - count
    });

    // if batch is not empty - add to list
    if (batch && isArrayExists(batch)) list.push(batch);
  } // end - trainees

  if (list && isArrayExists(list)) {
    let cIndex = 0;
    while (cIndex < list.length) {
      await callFunctionsAPI({
        url: "badge",
        action:
          status && status === "issue"
            ? "issue_oneoff_badge"
            : "draft_oneoff_badge",
        formData: {
          id,
          name,
          notes,
          badge_id,
          total_recipients: _size(recipients).toString(),
          recipients: cloneCollections(list[cIndex]),
          reset: cIndex === 0 ? "yes" : "no",
        },
      })
        .then(() => {
          cIndex++;
        })
        .catch((error) => {
          let errorMessage =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : error.message || "Unknown error. Please try again.";
          throw new Error(errorMessage);
        });
    }
  } // end - contacts

  return "done";
};

const FilterBox = ({
  authData,
  badge = "all",
  badges = [],
  // regions = [],
  onFilter = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      badge,
    });
  }, [open]);

  const handleFilter = () => {
    onFilter(formData);
    setOpen(false);
  };

  const handleUpdate = (newValue, key) => {
    setFormData({
      ...formData,
      [key]: newValue,
    });
  };

  const tagStyle = { marginRight: "5px", backgroundColor: "#ddd" };

  return (
    <>
      <div>
        <GreyTag key="region" style={tagStyle}>
          Badges:{" "}
          {badge && badge === "all"
            ? "All"
            : getSelectedValue(badges, badge, "id", "name", "-")}
        </GreyTag>
        <InfoButton
          size="small"
          key="filter"
          style={{ padding: "5px 8px" }}
          onClick={() => setOpen(true)}
        >
          <i className="fa fa-filter"></i>Customize Filter
        </InfoButton>
      </div>

      <ModalView
        open={open}
        title="Filter"
        onClose={() => setOpen(false)}
        actionLabel="Filter"
        doAction={handleFilter}
        contents={
          <div>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <div style={{ paddingTop: "7px" }}>
                  <FormSelect
                    value={formData.badge || ""}
                    label="Badge"
                    name="badge"
                    options={getSelectOptions({
                      list: _sortBy(badges, ["name"]),
                      options: [{ value: "all", label: "All Badges" }],
                      keys: { value: "id", label: "name" },
                    })}
                    // disabled={( isAdmin( authData ) ? false : true )}
                    onChange={handleUpdate}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        }
      />
    </>
  );
};

const getIssuedDateFromReport = (report) => {
  return new Promise((resolve, reject) => {
    if (report && report.created_on) {
      resolve(report);
    } else {
      firebase
        .database()
        .ref("badges_oneoff_reports_list/" + report.id)
        .orderByKey()
        .limitToFirst(1)
        .once("value")
        .then((snapshot) => {
          let user_badge_id = null;
          if (snapshot && snapshot.exists() && snapshot.hasChildren()) {
            snapshot.forEach((childSnapshot) => {
              let item = childSnapshot.val();
              if (item && item.user_badge_id) {
                user_badge_id = item.user_badge_id;
              }
            });
          }
          return user_badge_id
            ? firebase
                .firestore()
                .collection("user_badges")
                .doc(user_badge_id)
                .get()
            : null;
        })
        .then((doc) => {
          const user_badge = doc && doc.exists ? doc.data() : null;
          if (user_badge) {
            resolve({
              ...report,
              created_on:
                user_badge.created_on && user_badge.created_on.seconds
                  ? user_badge.created_on.seconds * 1000
                  : null,
            });
          } else {
            resolve(report);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

const getIssuedDateFromList = (list) => {
  return new Promise((resolve, reject) => {
    let promises = [];
    _forEach(list, (item) => {
      promises.push(getIssuedDateFromReport(item));
    });

    Promise.all(promises)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getList = ({ authData = {}, badge = "all" }) => {
  return new Promise((resolve, reject) => {
    let ref = firebase.database().ref("badges_oneoff_reports");

    if (
      !(
        authData &&
        isAdminType(authData) &&
        authData.region &&
        authData.region === PLATFORM_ADMIN_DEFAULT_REGION
      )
    ) {
      ref = ref
        .orderByChild("region")
        .equalTo(authData && authData.region ? authData.region : "noregion");
    }

    ref
      .once("value")
      .then((snapshot) => {
        let list = [];
        if (snapshot && snapshot.exists() && snapshot.hasChildren()) {
          snapshot.forEach((childSnapshot) => {
            let item = childSnapshot.val();
            item.id = childSnapshot.key;
            list.push(item);
          });
        }
        return getIssuedDateFromList(list);
      })
      .then((list) => {
        resolve(list);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const BadgesOneOffIssue = ({
  authData,
  dispatch,
  badgesList,
  badgesRand,
  certificatesList,
  regionsList,
}) => {
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [badge, setBadge] = useState("all");
  // const [ region, setRegion ] = useState(( isAdmin( authData ) ? 'all' : authData.region ));
  // const [ regions, setRegions ] = useState([]);
  const currentRegion =
    authData && authData.region && regionsList && isArrayExists(regionsList)
      ? regionsList.find((r) => r.id === authData.region)
      : null;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getOneOffBadges("oneoff"));
      dispatch(getMaintenance("certificates"));
      dispatch(getRegions());
      getList({ badge, authData })
        .then((data) => {
          setList(data);
        })
        .catch((error) => {
          triggerErrorAlert(getAPIErrorMessage(error));
        });
    }
    return () => {
      mounted = false;
      dispatch(resetRedux("oneoff_badges"));
    };
  }, []);

  useEffect(() => {
    if (badgesRand && certificatesList && list) {
      setLoaded(true);
    }
  }, [badgesRand, certificatesList, list]);

  const handleFilter = (data) => {
    setBadge(data.badge);
  };

  const handleIssueNew = (submitData) => {
    const apiNum = _random(1, 9999);
    dispatch(toggleModalProcessing(true, apiNum));
    dispatch(
      toggleLoader(
        true,
        "The process is on-going! Please do not close this window or click the Back button on your browser"
      )
    );
    let promises = [];
    if (
      submitData &&
      submitData.recipients &&
      isArrayExists(submitData.recipients)
    ) {
      promises.push(triggerBadgeIssue(submitData));
    } else {
      promises.push(
        callFunctionsAPI({
          url: "badge",
          action: "draft_oneoff_badge",
          formData: { ...submitData, total_recipients: "0" },
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        return getList({ badge, authData });
      })
      .then((list) => {
        setList(list);
        dispatch(toggleModalProcessing(false));
        dispatch(toggleLoader(false));
        if (submitData.status && submitData.status === "issue") {
          triggerSuccessAlert("Badge Issued");
        } else {
          triggerSuccessAlert("Badge issue Drafted");
        }
      })
      .catch((error) => {
        dispatch(toggleModalProcessing(false, apiNum));
        dispatch(toggleLoader(false));
        triggerErrorAlert(getAPIErrorMessage(error));
      });
  };

  const handleReportDelete = (report) => {
    const apiNum = _random(1, 9999);
    dispatch(toggleModalProcessing(true, apiNum));
    dispatch(
      toggleLoader(
        true,
        "The process is on-going! Please do not close this window or click the Back button on your browser"
      )
    );
    let promises = [];
    promises.push(
      callFunctionsAPI({
        url: "badge",
        action: "delete_oneoff_badge",
        formData: { id: report.id },
      })
    );

    Promise.all(promises)
      .then(() => {
        return getList({ badge, authData });
      })
      .then((list) => {
        setList(list);
        dispatch(toggleModalProcessing(false));
        dispatch(toggleLoader(false));
        triggerSuccessAlert("Draft Badge issue deleted");
      })
      .catch((error) => {
        dispatch(toggleModalProcessing(false, apiNum));
        dispatch(toggleLoader(false));
        triggerErrorAlert(getAPIErrorMessage(error));
      });
  };

  return (
    <AppWrapper
      title="Issue One-Off Badges"
      subtitle="Closing"
      onLoad={loaded ? false : true}
      contents={
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FilterBox
                authData={authData}
                // region={region}
                badge={badge}
                badges={badgesList}
                certificates={certificatesList}
                // regions={regions}
                onFilter={handleFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <List
                authData={authData}
                currentRegion={currentRegion}
                badges={badgesList}
                certificates={certificatesList}
                badge={badge}
                list={list}
                onIssueNew={handleIssueNew}
                onReportDelete={handleReportDelete}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state.auth && state.auth.user ? state.auth.user : null,
    badgesList:
      state.badges && state.badges.badges_one_off
        ? state.badges.badges_one_off
        : null,
    certificatesList:
      state.maintenance && state.maintenance.certificates
        ? state.maintenance.certificates
        : null,
    regionsList:
      state.maintenance && state.maintenance.regions
        ? state.maintenance.regions
        : false,
    badgesRand: state.badges && state.badges.rand ? state.badges.rand : null,
  };
};

export default compose(connect(mapStateToProps), withRouter)(BadgesOneOffIssue);
